import React from 'react'
import { Stack, Typography } from '@mui/material'
import { LogoTiny } from '../logo/LogoTiny'

export const ExconFooter = () => {
  return (
    <Stack
      p={1}
      alignItems='center'
      justifyContent='center'
      sx={{
        backgroundColor: 'background.paper',
        borderRadius: '0 0 15px 15px',
        borderTop: '2px solid',
        borderTopColor: 'grey.200',
        height: 40,
      }}
      flexDirection='row'
    >
      <Typography
        color='text.primary'
        textAlign={'center'}
        variant='h6'
        sx={{ display: 'inline', fontSize: '0.875rem', fontWeight: 500, marginRight: 0.5 }}
      >
        Powered by
      </Typography>
      <Stack position='relative' width={60}>
        <LogoTiny width={60} sx={{ color: 'text.primary', position: 'absolute', top: -11 }} />
      </Stack>
    </Stack>
  )
}

export default ExconFooter
